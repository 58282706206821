<template>
  <base-page
    class="responded"
    centered
  >
    <template #menu>
      <base-button
        v-if="isAuthenticated || $capacitor.isNative"
        class="button"
        label="Return"
        :to="{ name: 'home' }"
        size="small"
        type="ghost-inverted"
        icon="arrow-back-outline"
      />
    </template>
    <template #default>
      <div
        v-if="giphy"
        class="background"
        :style="{ backgroundImage: 'url(' + giphy + ')' }"
      />
      <div class="content">
        <img
          class="hands"
          :src="require('@/assets/images/emoji/raising-hands.png')"
          :srcset="require('@/assets/images/emoji/raising-hands.png') + ' 1x, '
            + require('@/assets/images/emoji/raising-hands@2x.png') + ' 2x, '
            + require('@/assets/images/emoji/raising-hands@3x.png') + ' 3x'"
          alt=""
        >
        <h1 class="title">
          Thank you!
        </h1>
        <p class="paragraph">
          We really appreciate your feedback, see you again next week!
        </p>
      </div>
    </template>
  </base-page>
</template>

<script>
import { get } from '@/utils/storage';

export default {
  data() {
    return {
      giphy: get('giphy'),
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  async created() {
    this.giphy = await get('giphy');
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
}

.content {
  position: relative;
  text-align: center;
}

.hands {
  width: 96px;
  height: 96px;
  margin-bottom: 16px;
  animation: hands 3500ms infinite ease;
}

/* @TODO Remove after iOS 14.2 release */
@supports (-webkit-touch-callout: none) {
  .hands {
    animation-timing-function: linear;
  }
}

.title {
  margin: 0 0 16px;
  font-weight: bold;
  font-size: 40px;
  font-family: $font-family-title;
  line-height: 1.2em;
}

.paragraph {
  margin: 0 0 8px;
  font-size: 20px;
}

@keyframes hands {
  0% { transform: translateY(0) scaleY(1); }
  24% { transform: translateY(0) scaleY(1); }
  28% { transform: translateY(-25px) scaleY(1.1); }
  34% { transform: translateY(0) scaleY(1); }
  38% { transform: translateY(-24px) scaleY(1.1); }
  44% { transform: translateY(0) scaleY(1); }
  // 46% { transform: translateY(0) scaleY(1); }
  // 50% { transform: translateY(-25px) scaleY(1.1); }
  // 56% { transform: translateY(0) scaleY(1); }
  // 60% { transform: translateY(-24px) scaleY(1.1); }
  // 66% { transform: translateY(0) scaleY(1); }
  100% { transform: translateY(0) scaleY(1); }
}

</style>
